import { storyData } from '../building-future/climate-change';
import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import CardImg1 from '@/assets/img/emergency-relief/conflict_card_img1@2x.jpg';
import CardImg2 from '@/assets/img/emergency-relief/conflict_card_img2@2x.jpg';
import CardImg3 from '@/assets/img/emergency-relief/conflict_card_img3@2x.jpg';
import CardImg4 from '@/assets/img/emergency-relief/conflict_card_img4@2x.jpg';
import CardImg5 from '@/assets/img/emergency-relief/conflict_card_img5@2x.png';
import WedoIco10 from '@/assets/img/emergency-relief/conflict_obj1_10@2x.png';
import WedoIco1 from '@/assets/img/emergency-relief/conflict_obj1_1@2x.png';
import WedoIco2 from '@/assets/img/emergency-relief/conflict_obj1_2@2x.png';
import WedoIco3 from '@/assets/img/emergency-relief/conflict_obj1_3@2x.png';
import WedoIco4 from '@/assets/img/emergency-relief/conflict_obj1_4@2x.png';
import WedoIco5 from '@/assets/img/emergency-relief/conflict_obj1_5@2x.png';
import WedoIco6 from '@/assets/img/emergency-relief/conflict_obj1_6@2x.png';
import WedoIco7 from '@/assets/img/emergency-relief/conflict_obj1_7@2x.png';
import WedoIco9 from '@/assets/img/emergency-relief/conflict_obj1_8@2x.png';
import WedoIco8 from '@/assets/img/emergency-relief/conflict_obj1_9@2x.png';
import Obj from '@/assets/img/emergency-relief/conflict_obj@2x.png';
import ObjM from '@/assets/img/emergency-relief/conflict_objM@2x.png';
import WedoIco11 from '@/assets/img/emergency-relief/covid19_obj1_6@2x.png';
import AccordionComponent, { AccordionItem } from '@/components/Accordion';
import { CampaignData } from '@/components/Card/CampaignCard';
import RelativeBoardCard from '@/components/Card/RelativeBoardCard';
import Container from '@/components/Container';
import { SectionWedo } from '@/components/EmergencyRelief';
import Image from '@/components/Image';
import LinkSafe from '@/components/LinkSafe';
import {
  SectionCard,
  SectionStroy,
  SectionTarget,
  SectionTop,
} from '@/components/Nodiscrimination';
import PageComponent from '@/components/PageComponent';
import { ProcessList } from '@/components/ProcessList';
import { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import SwiperBasic from '@/components/Swiper/SwiperBasic';
import ThumbnailFlex from '@/components/ThumbnailFlex';
import { Tit } from '@/components/Titles';
import YoutubeSingle from '@/components/YoutubeSingle';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';

const card = [
  {
    id: 1,
    title: `난민 캠프 설치 및 어린이 보호`,
    dec: `난민 어린이는 아동 착취 및 폭력, 조혼 등에 쉽게 노출됩니다. 유니세프는 난민 캠프를 설치하여, 생필품을 보급하고 깨끗한 식수를 제공하며, 어린이가 쉴 수 있는 터전을 만들어 줍니다. 더불어 어린이 친화공간을 만들어 난민 캠프에서도 어린이가 적절한 심리 사회 치료 및 교육을 받을 수 있도록 합니다.`,
    img: CardImg1,
  },
  {
    id: 2,
    title: `난민 어린이 관련 법제 제정`,
    dec: `가족을 잃은 어린이를 보호하고 재결합할 수 있도록 도우며, 난민 어린이와 가족들의 신분을 법적으로 보장하고 보호하는 법을 제정하도록 관련 기관에 촉구합니다. 또한, 이동 중에 있는 어린이의 경로와 상황을 추적할 수 있는 자료를 수집하며, 출생등록을 의무화합니다.`,
    img: CardImg3,
  },
  {
    id: 3,
    title: `난민 차별에 대응`,
    dec: `난민 어린이는 이동 중에 지나치는 국가 혹은 도착한 국가에서 인종 혐오의 대상이 되기 쉽습니다. 유니세프는 지역, 국가 기관과 협력하여 난민에 대한 이해와 포용이 넓은 사회를 만들도록 힘쓰며, 차별 금지 관련 법제 제정을 위해 협력합니다.`,
    img: CardImg2,
  },
  {
    id: 4,
    title: `무력 단체로부터 해방`,
    dec: `분쟁 등으로 집을 잃고 떠도는 어린이는 무력 단체에 납치되어 소년, 소녀 병으로 징집될 수 있습니다. 유니세프는 정부와 파트너와 협력하여 어린이 징집을 막고, 소년병 해방에 힘씁니다. 또한, 고향에 돌아가 마음의 안정을 얻고 생업을 꾸려 생활할 수 있도록 직업 및 사회화 교육을 진행합니다.`,
    img: CardImg4,
  },
  {
    id: 5,
    title: `폭발물 지뢰로부터 보호`,
    dec: `난민이 된 어린이는 이동 중에 무력 분쟁에 사용된 지뢰나 폭발물로 다치거나 죽을 확률이 높습니다. 지뢰나 폭발물로 사망한 사람 중 절반 이상은 어린이입니다. 이는 2016년과 대비해 12% 증가한 수치입니다. 유니세프는 아프가니스탄, 이란, 리비아, 시리아 등에 많은 지뢰와 폭발물을 제거하고, 다친 어린이를 치료합니다.`,
    img: CardImg5,
  },
];

const wedo = [
  {
    id: 1,
    tit: `2019년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco1,
        desc: [
          {
            dec: `부모를 잃은 전 세계 난민 어린이 <br /><strong>31,000명</strong> 가족과 재결합`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco2,
        desc: [
          {
            dec: `전 세계 <strong>76개국 170만 명</strong> <br />난민 어린이에게 보호 서비스 제공`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco3,
        desc: [
          {
            dec: `로힝야 난민 어린이 <br /><strong>27만 명</strong>에게 교육 서비스 제공`,
          },
        ],
      },
      {
        id: 4,
        img: WedoIco4,
        desc: [
          {
            dec: `튀르키예 난민 어린이 정규 교육 참여율 <strong>35%</strong>에서 <strong>63%</strong>로 증가 <br />(2016년 대비)`,
          },
        ],
      },
      {
        id: 5,
        img: WedoIco5,
        desc: [
          {
            dec: `니제르 난민 어린이 및 가족에게 보호 제도에 대해 정보를 알려주는 <br /><strong>통합 사회 복지 지원 센터 설립</strong>`,
          },
        ],
      },
      {
        id: 6,
        img: WedoIco6,
        desc: [
          {
            dec: `무력단체에 <br />납치된 어린이  <br /><strong>13,200명</strong> 해방`,
          },
        ],
      },
      {
        id: 7,
        img: WedoIco7,
        desc: [
          {
            dec: `시리아, 에티오피아, 나이지리아, 남수단, 예멘 등 분쟁지역 어린이에게 지속적인 <strong>기초 의료, 교육, 보호 서비스 제공</strong>`,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    tit: `2020년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco8,
        desc: [
          {
            dec: `인도네시아, 이라크, 에티오피아<br/>난민 어린이<strong> 16,130명 출생등록</strong>`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco2,
        desc: [
          {
            dec: `이라크, 방글라데시, 레바논<br/>난민 어린이 및 보호자 최소<br/><strong>17만 2천 명</strong>에게 <strong>심리 사회<br/>치료 서비스 제공</strong>`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco10,
        desc: [
          {
            dec: `난민 어린이 최소 <strong>51만 7천 명</strong>이<br/><strong>교육기관에 등록</strong>`,
          },
        ],
      },
      {
        id: 4,
        img: WedoIco9,
        desc: [
          {
            dec: `<strong>난민 110만 명</strong>이 새롭게<br/><strong>기본적인 수도 서비스 이용</strong>`,
          },
        ],
      },
      {
        id: 5,
        img: WedoIco5,
        desc: [
          {
            dec: `에티오피아, 온두라스, 인도네시아 <strong><br/>난민 어린이 60~100%</strong>에게<br/><strong>안전하고 이로운 보호정보 제공</strong>`,
          },
        ],
      },
    ],
  },
  {
    id: 3,
    tit: `2021년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco3,
        desc: [
          {
            dec: `긴급상황에 처한 난민 어린이 <strong>400만 명</strong>에게 교육 서비스 제공`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco11,
        desc: [
          {
            dec: `난민 어린이 <strong>500만 명</strong>에게 <br />기초 의료 서비스 제공`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco2,
        desc: [
          {
            dec: `난민 어린이 <strong>94만 명</strong>에게 <br />사회 보호서비스 제공`,
          },
        ],
      },
      {
        id: 4,
        img: WedoIco9,
        desc: [
          {
            dec: `난민 어린이 및 가족 <strong>730만 명</strong>에게 식수와 위생 서비스 제공`,
          },
        ],
      },
      {
        id: 5,
        img: WedoIco5,
        desc: [
          {
            dec: `난민 <strong>75만 명</strong> 주민 등록 <br />및 출생 등록 지원`,
          },
        ],
      },
    ],
  },
  {
    id: 4,
    tit: `2022년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco2,
        desc: [
          {
            dec: `우크라이나 어린이와 <br />가족 <strong>460만 명</strong> 심리치료`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco9,
        desc: [
          {
            dec: `로힝야 난민 <strong>30,493명</strong>에게 <br />깨끗한 식수 제공`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco10,
        desc: [
          {
            dec: `로힝야 난민캠프에 <strong>150개</strong> 다목적 센터 및 <strong>2,800개</strong> 임시 학습 센터 지원`,
          },
        ],
      },
      {
        id: 4,
        img: WedoIco3,
        desc: [
          {
            dec: `전 세계 난민 어린이 <strong>310만 명</strong>에게 정규, 비정규 교육 프로그램 제공`,
          },
        ],
      },
      {
        id: 5,
        img: WedoIco11,
        desc: [
          {
            dec: `부르키나파소, 말리, 니제르 <br />난민 어린이 <strong>110만 명</strong> 홍역 예방접종`,
          },
        ],
      },
    ],
  },
  {
    id: 5,
    tit: `2023년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco2,
        desc: [
          {
            dec: `가자지구 5세 미만 어린이 264,868명에게 영양지원`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco1,
        desc: [
          {
            dec: `가자지구 어린이 247,215명에게 방한용품 지원, 69,289명에게 놀이활동 지원`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco10,
        desc: [
          {
            dec: `우크라이나 난민 어린이 23,400명에게 심리치료 지원, 273,100명에게 학습지원`,
          },
        ],
      },
      {
        id: 4,
        img: WedoIco2,
        desc: [
          {
            dec: `수단 난민 어린이 15,414명에게 안전한 식수 공급, 10,242명에게 심리치료 지원`,
          },
        ],
      },
      {
        id: 5,
        img: WedoIco11,
        desc: [
          {
            dec: `수단 난민 5세 이하 어린이 6,379명 영양실조 치료`,
          },
        ],
      },
    ],
  },
];

const SectionWedoRe = styled(SectionWedo)`
  .process-ol {
    & > li:nth-child(1),
    & > li:nth-child(2),
    & > li:nth-child(3) {
      width: 33.33%;
    }

    ${breakpoint(`tablet`)} {
      & > li:nth-child(1) {
        width: 100% !important;
      }
    }
  }
`;
const NavigationButton = styled.div``;

const ConflictArea: React.FC<PageProps> = ({ location }) => {
  const [slideData, setSlideData] = useState<storyData[]>([]);
  const [thumbs, setThumbs] = useState<CampaignData[]>([]);

  const loadSlideData = useCallback(async () => {
    try {
      const {
        data: childStory,
      } = await BoardCommonControllerService.foListAllUsingGet({
        boardCategoryCode: ['ST13'],
        outDateDispYn: `Y`,
      });
      setSlideData(
        childStory.map((story: BoardVo) => ({
          id: story.boardIndexNumber,
          subject: story.subject,
          contents: story.contents,
          optData1: story.optData1,
          image: story.imgAttGrpNoTn,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  const loadThumbs = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['280', '285', '290'],
        pagePerCount: 8,
        category: ['A029'],
      });
      const articles = data as any;
      setThumbs(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link: `/what-we-do/news/${article.boardIndexNumber}`,
            date: article.firstRegisterDate,
            boardCategoryCode: article.boardCategoryCode,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadSlideData();
    loadThumbs();
  }, [loadSlideData, loadThumbs]);

  return (
    <LayoutWithTitle
      location={location}
      title="난민 어린이"
      description="for every child, safety"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  모든 어린이는 길 위에서도 충분히 보호받으며{` `}
                  <br className="small-hide" />
                  건강하게 성장할 권리가 있습니다
                </PageComponent>
              </Tit>
            </h2>
            <p className="head-dec">
              <PageComponent id="content1">
                2022년, 전 세계 어린이 4,330만 명이 난민이 되었습니다. 2010년과
                비교하면 두배나 늘었습니다. 전 세계 인구 중 약 30%만 어린이지만,
                난민 중 41%는 어린이입니다. 무방비의 어린이를 무차별적으로
                공격하는 무력 분쟁은 하루에 45건이 발생하고, 전쟁에 사용되었던
                지뢰나 폭발물로 죽는 사람의 반은 어린이입니다. 기후변화로 인한
                허리케인, 홍수, 가뭄 등 역시 어린이를 길 위로 내몹니다. 이
                과정에서 어린이는 노동, 조혼, 아동착취, 인신매매, 무력 단체 납치
                등 다양한 위험에 처하게 됩니다. 어린이는 어디에서 왔든, 어디로
                가든, 어떻게 난민이 되었든, 어린이입니다. 유니세프는 길 위의
                어린이가 충분히 보호받고 잠재력을 발휘할 수 있도록 지원합니다.
              </PageComponent>
            </p>
          </SectionHeader>
        </Container>
        <PageComponent id="youtube1">
          <YoutubeSingle videoId="E2HV6tCNz1Y" />
        </PageComponent>
      </SectionTop>

      <SectionCard className="by-sub-main-layout">
        <Container>
          <ThumbnailFlex thumbs={card} />
        </Container>
      </SectionCard>

      <SectionWedoRe className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                난민 어린이를 위해, <br />전 세계 파트너와 함께 유니세프가 한 일
              </Tit>
            </h2>
          </SectionHeader>

          <AccordionComponent initialKey={wedo[wedo.length - 1].id}>
            {wedo.map((row) => (
              <AccordionItem
                key={row.id}
                toggleKey={row.id}
                renderToggle={(onClick) => (
                  <NavigationButton
                    onClick={onClick}
                    className="accordion-opener"
                  >
                    {row.tit}
                  </NavigationButton>
                )}
              >
                <div className="accordion-body">
                  <ProcessList
                    itemData={row.child}
                    type={row.col ? row.col : ``}
                  />
                </div>
              </AccordionItem>
            ))}
          </AccordionComponent>
        </Container>
      </SectionWedoRe>

      <SectionStroy className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">어린이 이야기</Tit>
            </h2>
          </SectionHeader>
        </Container>
        <div className="section-content">
          <div className="obj t1" />
          <div className="obj t2" />
          <Container>
            <div className="content-container">
              <SwiperBasic
                css={`
                  padding: 0;
                `}
              >
                <Swiper slidesPerView="auto" navigation>
                  {slideData.map((row) => (
                    <SwiperSlide key={row.id}>
                      <div className="story-flex">
                        <div className="col-img">
                          <Image pcSrc={row.image} mobileSrc={row.image} />
                        </div>
                        <div className="col-dec">
                          <header>
                            <Tit size="s4" color="white">
                              &ldquo;{row.subject}&rdquo;
                            </Tit>
                            {row.optData1 !== null && row.optData1 !== `` && (
                              <p> - {row.optData1} </p>
                            )}
                          </header>
                          <p className="dec">{row.contents}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </SwiperBasic>
            </div>
          </Container>
        </div>
      </SectionStroy>
      {thumbs.length > 0 && (
        <SectionNews className="by-sub-main-layout">
          <Container>
            <SectionHeader className="with-desc">
              <div className="flex-middle">
                <h2>
                  <Tit size="s1">최근 소식</Tit>
                </h2>
                <LinkSafe to="/what-we-do/news?category=A029" className="more">
                  <span className="txt">더 보기</span>
                </LinkSafe>
              </div>
            </SectionHeader>
            <RelativeBoardCard
              newslist={thumbs}
              isDate={false}
              isSwiper
              overflowHidden
            />
          </Container>
        </SectionNews>
      )}
    </LayoutWithTitle>
  );
};

export default ConflictArea;
